import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import "./Navbar.scss";
import { ReactComponent as Logo } from "../assets/nav-vera-icon.svg";
import { ReactComponent as VoiceIcon } from "../assets/voice-icon.svg";
import Typography from "@mui/material/Typography";
import {Button}  from "@mui/material";
import { useNavigate } from "react-router";
const Navbar = () => {
  const navigate = useNavigate();
  const location = window.location.href;
  const blogLocation =
    location === "https://www.veralegal.uk/header/public-header/"
      ? "https://www.veralegal.uk"
      : "";
  const navigationURL = () => {
    if (blogLocation) {
      window.open(blogLocation, "_blank");
    } else {
      navigate("/");
      window.scrollTo(0, 0);
    }
  };

  return (
    <Box className="nav-wrapper">
      <Container className="nav-container custom-container">
        <Box className="logo">
          <Logo
            onClick={navigationURL}
            style={{ cursor: "pointer", width: "3.8vw" }}
          />
        </Box>
        <ul>
          <li>
            <a
              className="headerText"
              onClick={() => !blogLocation && navigate("/")}
              target={blogLocation ? "_blank" : ""}
              rel="noreferrer"
              href={
                blogLocation ? `${blogLocation}/` + "#services" : "#services"
              }
            >
              Services
            </a>
          </li>
          <li>
            <a
              className="headerText"
              onClick={() => !blogLocation && navigate("/")}
              target={blogLocation ? "_blank" : ""}
              href={blogLocation ? `${blogLocation}/` + "#process" : "#process"}
            >
              Process
            </a>
          </li>
          <li>
            <a
              className="headerText"
              onClick={() => !blogLocation && navigate("/")}
              target={blogLocation ? "_blank" : ""}
              href={blogLocation ? `${blogLocation}/` + "#faqs" : "#faqs"}
            >
              FAQs
            </a>
          </li>
          <li>
            <a
              className="headerText"
              onClick={() => !blogLocation && navigate("/")}
              target={blogLocation ? "_blank" : ""}
              href={blogLocation ? `${blogLocation}/` + "#contact" : "#contact"}
            >
              Contact Us
            </a>
          </li>
          <li>
            <a
              className="headerText"
              href={"/blogs"}
              //href="https://blog.veralegal.uk/"
              rel="noreferrer"
            >
              Blog
            </a>
          </li>
        </ul>

        <Box className="btns">
          <Typography
            variant="body1"
            className="login-btn"
            onClick={() => navigate("/login")}
            color="initial"
          >
            Log in
          </Typography>

          <Button
            className="started-btn"
            onClick={() => {
              localStorage.clear();
              navigate("/onboarding/0");
            }}
            variant="contained"
            color="primary"
          >
            Get Started
          </Button>

          <Button
            className="voice-btn"
            onClick={() => navigate("/onboarding/0?selectedOption=voice-memo")}
            variant="contained"
            color="primary"
          >
            Tell Us
            <VoiceIcon /> 
          </Button> 
        </Box>
      </Container>
    </Box>
  );
};

export default Navbar;
