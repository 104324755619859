import React from 'react';
import { Stack, Grid, Box, Typography, } from '@mui/material';

import spotify from '../../assets/public-widgets/spotify.png';
import { data } from './data';
const Podcast = () => {
  return (
    <Box sx={{
      py: 3,
      maxWidth: '1440px',
      mx: 'auto',
    }}>
      <Grid container spacing={9}>
        {data?.map((item, i) => (
          <Grid key={i} item xs={12} md={4}>
            <Stack
              component={'a'}
              href='https://veralegal.uk'
              target='_blank'
              sx={{
                p: 2,
                background: '#fff',
                borderRadius: '8px',
                height: '100%',
                textDecoration: 'none'
              }}
            >
              <Box position={'relative'} sx={{ mb: 2 }}>
                <Box
                  component={'img'}
                  src={item?.img}
                  sx={{
                    width: '100%',
                    height: 180,
                    objectFit: 'cover',
                  }}
                />
                <Box
                  component={'img'}
                  src={spotify}
                  sx={{
                    width: 58,
                    height: 58,
                    position: 'absolute',
                    top: 'calc(50% - 29px)',
                    left: 'calc(50% - 29px)',
                    zIndex: 2,
                  }}
                />
              </Box>
              <Typography variant='h5' sx={{ mb: 1.5, fontWeight: 500 }} color={'text.primary'}>
                {item.title}
              </Typography>
              <Typography variant='body1' color={'text.secondary'} sx={{ mb: 2 }}>
                {item.description}
              </Typography>
              <Typography variant='body2' color={'text.secondary'} sx={{ mt: 'auto' }}>
                2024-01-20
              </Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default Podcast
