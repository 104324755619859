
import image1 from '../../assets/public-widgets/image1.jpg';
import image2 from '../../assets/public-widgets/image2.jpg';
import image3 from '../../assets/public-widgets/image3.jpg';

export const data = [
    {
        title: 'Does your BRP expire before your visa/leave to remain? what k...',
        description: 'Completing the travel history section in your UK visa is a crucial step, and precession is key. Heres a no nonsense guide:1. Be Chronological: Start',
        img: image1,
    },
    {
        title: 'How to complete the travel history section in your visa applicati...',
        description: 'Preparing for the ILR (Indefinite Leave to Remain) application is a crucial step in the immigration process, and taking initial steps before consulting solicito...',
        img: image2,
    },
    {
        title: 'How to complete the travel history section in your visa applicati...',
        description: 'Preparing for the ILR (Indefinite Leave to Remain) application is a crucial step in the immigration process, and taking initial steps before consulting solicito...',
        img: image3,
    },
];